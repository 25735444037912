import {
  Applicant,
  ApplicantAddressType,
  ApplicationProps,
  JointholderRelationMaster,
  individuals_Poa_nonIndividuals_Documents,
  Document,
  FileType,
  DocumentType,
} from '../redux-store/types/api-types';
import {
  applicantStatusMasters,
  APPLICATION_LISTING_STATUS,
  APPLICATION_TYPE,
  APPLICATION_TYPE_FOR_DOCUMENTS,
  BloodRelations,
  SALT,
  SALT2,
  USER_ROLES,
  yesAndNoMasters,
} from './constant';
import { ToWords } from 'to-words';
import { individualPanRegex, nonIndividualPanRegex, numberRegex } from './regex';
import _ from 'lodash';
import { updatedRiskProfile } from '../components/investors/riskProfileDetails';
import {
  documentProps,
  document_object,
  nomineeDocumentProps,
} from '../components/investors/documentDetails';
import { documentProps as nonIndDocumentProps } from '../components/NonIndividualInvestor/DocumentDetails';
import { getFieldTitle } from '../components/ApplicationDetails/DocumentDetails';
import { AuthTokenType } from '../redux-store/types/auth';
import en from '../lang/en-us';

export const maskMobileNumber = (mobileNumber: string): string => {
  if (!mobileNumber) {
    return '';
  }
  return (
    mobileNumber.slice(0, 2) +
    mobileNumber.slice(2, mobileNumber.length - 2).replace(/\d/g, '*') +
    mobileNumber.slice(mobileNumber.length - 2)
  );
};

export const getApplicantName = (index: number, isNomineeOrBank?: boolean): string => {
  if (index === 1) {
    return isNomineeOrBank ? 'First' : 'First/Sole';
  } else if (index === 2) {
    return 'Second';
  } else {
    return 'Third';
  }
};

export const getBankName = (index: number): string => {
  if (index === 1) {
    return 'Bank account from where funds are debited';
  } else {
    return 'Bank Account mapped to CML';
  }
};

export const minDateForContributor = (): Date => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
};

export const getFirstHolderName = (applicants: Partial<Applicant>[]): string => {
  return (
    applicants.find((applicant) => ['firstapplicant', '1'].includes(applicant.applicant_type || ''))
      ?.name || 'N/A'
  );
};

export const getApplicantType = (applicantType: string | undefined): string => {
  if (applicantType) {
    if (['firstapplicant', '1'].includes(applicantType)) {
      return '1';
    } else if (['secondapplicant', '2'].includes(applicantType)) {
      return '2';
    } else {
      return '3';
    }
  }
  return '';
};

export const formatToIndianCurrency = (value: number | null): string => {
  const options = { style: 'currency', currency: 'INR' };
  if (value) {
    return new Intl.NumberFormat('en-IN', options).format(value);
  }
  return '';
};

export function toExactSubstr(str: string): string {
  const a = str.split('.');
  if (a.length == 2) {
    return a[0].concat('.', a[1].substr(0, 2));
  }
  return str;
}

export const formatCurrency = (value?: number | null): string => {
  const formats = ['K', 'L', 'Cr'];
  let amount: any = Number(value);
  formats.some((key, i) => {
    const size = Math.pow(10, i === 0 ? 3 : 3 - 1);
    if (size <= amount) {
      amount = toExactSubstr(`${amount / size}`);
      if (amount >= 100 && i < formats.length - 1) {
        i++;
      } else {
        // amount = (amount * decPlaces) / decPlaces;
        amount += ' ' + formats[i];
        return true;
      }
    } else {
      return true;
    }
  });
  return amount;
};

export const EIGHTEEN_YEARS_IN_MILLISECONDS = 18 * 31556926 * 1000; //  18 * no of seconds in a Year * 1000

export const isMinor = (dateOfBirth = ''): boolean => {
  return new Date().getTime() - new Date(dateOfBirth).getTime() < EIGHTEEN_YEARS_IN_MILLISECONDS;
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
const countryCodes = require('country-codes-list');
export const getCountryCodes = (): { label: string; key: string; countryCode: string }[] => {
  const countryCodesObject: { [key: string]: string } = countryCodes.customList(
    'countryCode',
    '{countryNameEn}: +{countryCallingCode}'
  );

  const modifiedCodes = Object.keys(countryCodesObject).map((cc) => ({
    label: countryCodesObject[cc],
    key: countryCodesObject[cc].split(':')[1].trim(),
    countryCode: cc,
  }));
  return modifiedCodes;
};

export const getAddressFields = (addressType: string): ApplicantAddressType => {
  return {
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    country: addressType === 'overseas' ? '' : 'INDIA',
    pincode: '',
    permanentAddressSameAsCorresponding: false,
    address_type: addressType,
    isActive: true,
    fetchedFromKRA: null,
  };
};

export const getAddressData = (
  addressType: string,
  addresses: Partial<ApplicantAddressType>[] | undefined
): Partial<ApplicantAddressType> => {
  return (
    addresses?.find((address) => address.address_type === addressType) ||
    getAddressFields(addressType)
  );
};

export function getRelation(relationValue?: string | null): boolean {
  return ['Mother', 'Father', 'Daughter', 'Son', 'Spouse', 'Brother', 'Sister', ''].includes(
    relationValue || ''
  );
}

export const saveForLater = (
  role: string,
  id?: string,
  applicant1ReferenceId?: string | null
): string => {
  return role === USER_ROLES.INVESTOR
    ? `/investment-details/${applicant1ReferenceId}/application-details`
    : role === USER_ROLES.POAAPPROVER || role === USER_ROLES.AMCAPPROVER
    ? `/application-details/${id}`
    : '/applications';
};

export const maxAge = (dateOfBirth = ''): boolean => {
  const thisYear = new Date().getFullYear();
  return thisYear - new Date(dateOfBirth).getFullYear() > 125;
};
export const futureAge = (dateOfBirth = ''): boolean => {
  return new Date(dateOfBirth) > new Date();
};
export function currencyConversion(num?: string | number | null): string | null {
  if (!num) return '';
  const toWords = new ToWords();
  const words = toWords.convert(Number(num), { currency: true });
  return words;
}

export function checkIfApplicationIsNonIndividual({ applicationType }: ApplicationProps): boolean {
  return applicationType === APPLICATION_TYPE.NON_INDIVIDUAL;
}

// eslint-disable-next-line
export const preventSpecialCharacters = (e: any) => {
  if (
    !((e.ctrlKey || e.metaKey) && e.keyCode == 67) &&
    !((e.ctrlKey || e.metaKey) && e.keyCode == 86) &&
    !((e.ctrlKey || e.metaKey) && e.keyCode == 88) &&
    !((e.ctrlKey || e.metaKey) && e.keyCode == 90) &&
    !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter'].includes(e.key) &&
    !numberRegex.test(e.key)
  ) {
    e.preventDefault();
  }
};
export function allowOnlyNumbers(e: any): void {
  if (['-', '+', 'e', 'E'].includes(e.key)) {
    e.preventDefault();
  }
}
export const permanentAddressSameAsCorresponding = (
  updatedCorrespondingAddress: Partial<ApplicantAddressType>,
  addressType: string,
  addresses: Partial<ApplicantAddressType>[] | undefined
): Partial<ApplicantAddressType> => {
  // eslint-disable-next-line
  const { address1, address2, city, pincode, state, country, ...rest } =
    updatedCorrespondingAddress;
  return {
    ...getAddressData(addressType, addresses),
    address1: address1,
    address2: address2,
    city: city,
    pincode: pincode,
    state: state,
    country: country,
    address_type: addressType,
  };
};
export const _updatedAddresses = (
  updatedAddresses: {
    nationality?: string | null | undefined;
    status?: string | null | undefined;
    permanent?: Partial<ApplicantAddressType> | undefined;
    correspondence?: Partial<ApplicantAddressType> | undefined;
    overseas?: Partial<ApplicantAddressType> | undefined;
    [key: string]: string | Partial<ApplicantAddressType> | null | undefined;
  },
  applicant: Partial<Applicant>
): Partial<ApplicantAddressType>[] => {
  const { nationality, status, ...addressProps } = updatedAddresses;
  return Object.keys(addressProps)
    .map((addressType) => {
      // if (applicant.nationality?.toLowerCase() === 'indian' && addressType === 'overseas') {
      //   return {};
      // }
      if (
        addressType === 'permanent' &&
        addressProps['overseas']?.permanentAddressSameAsCorresponding
      ) {
        return permanentAddressSameAsCorresponding(
          addressProps['overseas'],
          addressType,
          applicant.addresses
        );
      }
      if (
        addressType === 'permanent' &&
        addressProps['correspondence']?.permanentAddressSameAsCorresponding &&
        !addressProps['overseas']
      ) {
        return permanentAddressSameAsCorresponding(
          addressProps['correspondence'],
          addressType,
          applicant.addresses
        );
      }
      if (
        Object.keys(addressProps).length === 1 &&
        addressProps['overseas']?.permanentAddressSameAsCorresponding
      ) {
        return [
          {
            ...permanentAddressSameAsCorresponding(
              addressProps['overseas'],
              'permanent',
              applicant.addresses
            ),
          },
          {
            ...getAddressData(addressType, applicant.addresses),
            ...(addressProps[addressType] as unknown as Partial<ApplicantAddressType>),
            address_type: addressType,
          },
        ];
      }
      if (
        Object.keys(addressProps).length === 1 &&
        addressProps['correspondence']?.permanentAddressSameAsCorresponding
      ) {
        return [
          {
            ...permanentAddressSameAsCorresponding(
              addressProps['correspondence'],
              'permanent',
              applicant.addresses
            ),
          },
          {
            ...getAddressData(addressType, applicant.addresses),
            ...(addressProps[addressType] as unknown as Partial<ApplicantAddressType>),
            address_type: addressType,
          },
        ];
      }
      return {
        ...getAddressData(addressType, applicant.addresses),
        ...(addressProps[addressType] as unknown as Partial<ApplicantAddressType>),
        address_type: addressType,
      };
    })
    .flat()
    .filter((address) => Object.keys(address).length);
};
export const applyRoleBasedStatus = (role: any): boolean => {
  return [
    USER_ROLES.RM,
    USER_ROLES.SUBDISTRIBUTOR,
    USER_ROLES.DISTRIBUTOR,
    USER_ROLES.AMCAPPROVER,
  ].includes(role);
};

export const checkKraBasedOnStatus = (status: any): boolean => {
  return ![
    APPLICATION_LISTING_STATUS.invitationexpired,
    APPLICATION_LISTING_STATUS.signed,
    APPLICATION_LISTING_STATUS.completed,
    APPLICATION_LISTING_STATUS.rejected,
  ].includes(APPLICATION_LISTING_STATUS[status]);
};

//this method not only for application comparison, it is general function for comparing data
export const applicationComparison = (
  existingApplication: Partial<ApplicationProps> | null | string | any,
  updatedApplication: Partial<ApplicationProps> | any
): boolean => {
  return _.isEqual(existingApplication, updatedApplication);
};

export const sendApplication_Nri = (application?: ApplicationProps | null): boolean | undefined => {
  return application?.applicants.map((applicant) => applicant.status).includes('NRI');
};

export const checkForCorrespondenceAddress = (
  nationality: string | null | undefined,
  status: string | null | undefined
): boolean => {
  return (
    nationality?.toLowerCase() === 'indian' &&
    applicantStatusMasters[status as string] === applicantStatusMasters.Individual
  );
};

export const setUpFeeCalculations = (
  commitmentAmount: number,
  setupFeePercentage: number
): { feeAmount: number; feeGst: number; totalSetupFee: number } => {
  const setupFeeAmount = (Number(commitmentAmount) * Number(setupFeePercentage)) / 100;
  const GstForSetupFee = (setupFeeAmount * 18) / 100;
  return {
    feeAmount: Number(setupFeeAmount),
    feeGst: Number(GstForSetupFee),
    totalSetupFee: Number(setupFeeAmount + GstForSetupFee),
  };
};

export const checkAddressField = (data?: string) => {
  if (data) {
    if (data.includes(' ,')) {
      return data.split(' ,').join(',') + ' ';
    }
    return data.includes(',') ? data + ' ' : data + ', ';
  }
  return '';
};

export const getBankAddress = (
  address1: string | undefined,
  address2: string | undefined,
  address3: string | undefined
): string => {
  return `${address2 ? checkAddressField(address1) || '' : address1 || '' || ''}${
    address3 ? checkAddressField(address2) || '' : address2 || ''
  }${address3 || ''}`;
};

export const checkInvestorProfilebasedOnScheme = (schemeCode: string) => {
  if (schemeCode === 'KAF') {
    return true;
  }
  return false;
};

export const checkIsAccreditedInvestor = (applicants: Partial<Applicant>[]) => {
  let checkIsAccredited = false;
  applicants?.map((applicant) => {
    if (yesAndNoMasters[applicant.isAccreditedInvestor || ''] === yesAndNoMasters.yes) {
      checkIsAccredited = true;
    }
  });
  return checkIsAccredited;
};

export const numToAlpMonth = (numMonth: any, month: any) => {
  numMonth?.map((monthNum: any) => {
    if (monthNum === 1) {
      month.push('Jan');
    } else if (monthNum === 2) {
      month.push('Feb');
    } else if (monthNum === 3) {
      month.push('Mar');
    } else if (monthNum === 4) {
      month.push('Apr');
    } else if (monthNum === 5) {
      month.push('May');
    } else if (monthNum === 6) {
      month.push('Jun');
    } else if (monthNum === 7) {
      month.push('Jul');
    } else if (monthNum === 8) {
      month.push('Aug');
    } else if (monthNum === 9) {
      month.push('Sep');
    } else if (monthNum === 10) {
      month.push('Oct');
    } else if (monthNum === 11) {
      month.push('Nov');
    } else if (monthNum === 12) {
      month.push('Dec');
    }
  });
};

export const removeSingleQuote = (value: string | null | undefined): any => {
  if (value && value.includes("'")) return value.split("'").join('');
  else return value;
};

export function removeEqualOperator(value = ''): string {
  const lastTwoIndex = value.length - 2;
  let encodeString = value.slice(0, lastTwoIndex);
  const slicedStr = value.slice(lastTwoIndex, value.length);
  slicedStr.split('').forEach((char) => {
    if (char !== '=') {
      encodeString += char;
    }
  });
  return encodeString;
}

export function encodeBase64(value: string): string {
  const encodeValue = btoa(value);
  const encodeSubString = removeEqualOperator(encodeValue);
  const encodePayload = btoa(`${SALT}${encodeSubString}${SALT2}`);
  return encodePayload;
}

export function getNomineeRelation(relationValue?: string | null): boolean {
  return ['MOTHER', 'FATHER', 'DAUGHTER', 'SON', 'SPOUSE', 'BROTHER', 'SISTER', ''].includes(
    relationValue || ''
  );
}

export function updatedCommitmentAmount(
  minCommitmentAmount: number,
  applicants: Partial<Applicant>[],
  data: JointholderRelationMaster
): number | void {
  if (applicants.length === 2) {
    const applicantRelation = applicants
      .map((applicant) => applicant.relationShipWithFirstApplicant)
      .filter((ele) => ele)
      ?.toString();
    const relation = BloodRelations.includes(applicantRelation) ? applicantRelation : 'Others';
    const amount = data.singleJointHolder
      .map((jointHolder) => {
        if (jointHolder.jointHolder1RelationShip === relation) {
          return (minCommitmentAmount * jointHolder.minimumAmountPercentage) / 100;
        }
      })
      .filter((ele) => ele)
      .toString();
    return Number(amount);
  }
  if (applicants.length === 3) {
    const applicantRelation = applicants
      .map((applicant) => applicant.relationShipWithFirstApplicant)
      .filter((ele) => ele);
    const relation = applicantRelation.map((_relation) =>
      BloodRelations.includes(_relation as string) ? _relation : 'Others'
    );
    const amount = data.twoJointHolders
      .map((jointHolder) => {
        if (
          relation[applicants.length - 3] === jointHolder.jointHolder1RelationShip &&
          relation[applicants.length - 2] === jointHolder.jointHolder2RelationShip
        ) {
          return (minCommitmentAmount * jointHolder.minimumAmountPercentage) / 100;
        }
      })
      .filter((ele) => ele)
      .toString();
    return Number(amount);
  }
}

export const changeGoalVal = (
  ans: string,
  riskProfileData: updatedRiskProfile[],
  question: string
) => {
  const ansArray = ans.split('*').map((val) => {
    const index = val.indexOf('_');
    const result = val.substring(0, index);
    return result;
  });
  const valueArray = riskProfileData.find((profile) => profile?.question === question)?.values;
  const labelsArray = ansArray.map((key) => {
    const item = valueArray?.find((val) => val.key === key);
    return item ? `${item.label}` : key;
  });
  return labelsArray;
};
export function checkifFatcaYes(
  taxResidentOfAnyCountryOtherThanIndia: boolean | undefined,
  citizenOfAnyCountryOtherThanIndia: string | null | undefined,
  countryOfBirthOtherThanIndia: string | null | undefined,
  poaMandateHolderOutsideIndia: string | null | undefined,
  addressTelephoneOutsideIndia: string | null | undefined
): any {
  return (
    taxResidentOfAnyCountryOtherThanIndia?.toString() === 'true' ||
    yesAndNoMasters[citizenOfAnyCountryOtherThanIndia || ''] === yesAndNoMasters.yes ||
    yesAndNoMasters[countryOfBirthOtherThanIndia || ''] === yesAndNoMasters.yes ||
    yesAndNoMasters[poaMandateHolderOutsideIndia || ''] === yesAndNoMasters.yes ||
    yesAndNoMasters[addressTelephoneOutsideIndia || ''] === yesAndNoMasters.yes
  );
}

export function checkifFatcaYesAndTypeOthers(
  taxResidentOfAnyCountryOtherThanIndia: boolean | undefined,
  citizenOfAnyCountryOtherThanIndia: string | null | undefined,
  countryOfBirthOtherThanIndia: string | null | undefined,
  poaMandateHolderOutsideIndia: string | null | undefined,
  addressTelephoneOutsideIndia: string | null | undefined,
  idType: string | null | undefined
): any {
  return (
    (taxResidentOfAnyCountryOtherThanIndia?.toString() === 'true' ||
      yesAndNoMasters[citizenOfAnyCountryOtherThanIndia || ''] === yesAndNoMasters.yes ||
      yesAndNoMasters[countryOfBirthOtherThanIndia || ''] === yesAndNoMasters.yes ||
      yesAndNoMasters[poaMandateHolderOutsideIndia || ''] === yesAndNoMasters.yes ||
      yesAndNoMasters[addressTelephoneOutsideIndia || ''] === yesAndNoMasters.yes) &&
    idType === 'OTHERS'
  );
}

export const individualIndian = (value: Partial<Applicant>): boolean => {
  return value.nationality?.toUpperCase() === 'INDIAN';
};

export const isAccreditedDocumentVisible = (documentType: string, isAccreditedInvestor: string) => {
  return (
    yesAndNoMasters[isAccreditedInvestor] === yesAndNoMasters.no &&
    documentType === 'accreditedInvestorCertificate'
  );
};

export const poaFirstApplicantDocumentVisible = (documentType: string, applicant_type?: string) => {
  return (
    applicant_type !== '1' &&
    [
      'poaNotarized',
      'authorized_signatories_list',
      'authorized_signatories_1',
      'authorized_signatories_2',
      'referralFeePage',
    ].includes(documentType)
  );
};

export const poaApproverMandetryDocs = (documentType: string) => {
  return [
    'poaNotarized',
    'investorsConsent',
    'authorized_signatories_list',
    'authorized_signatories_1',
    'authorized_signatories_2',
  ].includes(documentType);
};
export const isValidPan = (pan: string): boolean =>
  [individualPanRegex, nonIndividualPanRegex].some((pattern) => pattern.test(pan));

export const isNumberNull = (num: string | number | null): boolean => num !== 0 && !num;
export const getArrayLength = (arrayList: number) => {
  return Array.from(
    {
      length: arrayList,
    },
    (value, index) => index + 1
  );
};

export function checkIfApplicationIsNonIndividualPOA(application: ApplicationProps): boolean {
  if (application) {
    const { applicationType, hasPOA } = application;
    return applicationType === APPLICATION_TYPE.NON_INDIVIDUAL && hasPOA;
  } else return false;
}

export const fatcaDocumentCheck = (
  hasPOA: boolean,
  fatcaDocument: boolean | null,
  docType: string
): boolean | null => {
  return hasPOA && fatcaDocument && docType === 'fatca';
};

export function checkIfApplicationIsIndividualPOA(application: ApplicationProps): boolean {
  if (application) {
    const { applicationType, hasPOA } = application;
    return applicationType === APPLICATION_TYPE.INDIVIDUAL && hasPOA;
  } else return false;
}

export const checkValidationBasedOnDate = (
  date: string | number | Date,
  validationStartDate: string
): boolean => {
  if (!date) return false;
  if (!validationStartDate) return true;
  const dateForCompare = date ? new Date(date) : new Date();
  const dateForApplyValidations = new Date(validationStartDate || '');
  return dateForCompare.getTime() >= dateForApplyValidations.getTime();
};

export const nominationPageDocument = (
  date: string | number | Date,
  validationStartDate: string,
  docType: string
): boolean => {
  return checkValidationBasedOnDate(date, validationStartDate) && docType === 'nomination_page';
};

export const individualDocuments = (
  application: ApplicationProps | null,
  role: USER_ROLES,
  nomineeValidationDate: string,
  dematDocumentValidation: string,
  poaDocValidation: string,
  documentsData?: Document | string
): documentProps[] => {
  const {
    applicants = [],
    hasPOA = false,
    createdAt = '',
    doNotWishToFillRiskProfile = false,
    scheme,
  } = application || {};
  return applicants.map((applicant, applicant_index) => {
    let docData = '';
    if (
      !hasPOA &&
      applicantStatusMasters[applicant.status as string] === applicantStatusMasters.Individual
    ) {
      docData = APPLICATION_TYPE_FOR_DOCUMENTS.INDIVIDUAL;
    }
    if (
      hasPOA &&
      applicantStatusMasters[applicant.status as string] === applicantStatusMasters.Individual
    ) {
      docData = APPLICATION_TYPE_FOR_DOCUMENTS.INDIVIDUAL_POA;
    }
    if (
      !hasPOA &&
      applicantStatusMasters[applicant.status as string] === applicantStatusMasters.NRI
    ) {
      docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI;
    }
    if (
      hasPOA &&
      applicantStatusMasters[applicant.status as string] === applicantStatusMasters.NRI
    ) {
      docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI_POA;
    }
    return {
      documents:
        typeof documentsData !== 'undefined'
          ? ((documentsData as Document)[docData] || [])
              .filter((doc) => {
                if (nominationPageDocument(createdAt, nomineeValidationDate, doc.documentType)) {
                  return;
                }
                return doc;
              })
              .filter((ele) => ele)
              .filter((doc) => {
                if (
                  hasPOA &&
                  poaFirstApplicantDocumentVisible(doc.documentType, applicant?.applicant_type)
                ) {
                  return;
                }
                return doc;
              })
              .filter((ele) => ele)
              .filter((doc) => {
                if (
                  ['authorized_signatories_1', 'authorized_signatories_2'].includes(
                    doc.documentType
                  ) &&
                  checkValidationBasedOnDate(createdAt, poaDocValidation)
                ) {
                  return;
                }
                return doc;
              })
              .filter((doc) => doc)
              .filter((doc) => {
                if (
                  scheme?.schemeCode.toUpperCase() !== 'LS' &&
                  doc.documentType === 'annual_declaration_consent'
                ) {
                  return;
                }
                return doc;
              })
              .filter((ele) => ele)
              .map((_doc) => {
                if (
                  [USER_ROLES.POAAPPROVER, USER_ROLES.AMCAPPROVER].includes(role) &&
                  poaApproverMandetryDocs(_doc.documentType)
                ) {
                  return {
                    ..._doc,
                    required:
                      ['authorized_signatories_1', 'authorized_signatories_2'].includes(
                        _doc.documentType
                      ) && checkValidationBasedOnDate(createdAt, poaDocValidation)
                        ? 'false'
                        : 'true',
                  };
                }
                return _doc;
              })
              .map((_doc) => {
                if (_doc.documentType === 'referralFeePage') {
                  return { ..._doc, required: 'true' };
                }
                if (
                  scheme?.schemeCode.toUpperCase() === 'LS' &&
                  _doc.documentType === 'annual_declaration_consent'
                ) {
                  return { ..._doc, required: 'true' };
                }
                return _doc;
              })
              .map((_doc) => {
                if (
                  _doc.documentType === 'cml' &&
                  checkValidationBasedOnDate(createdAt, dematDocumentValidation)
                ) {
                  return { ..._doc, required: 'true' };
                }
                return _doc;
              })
              .filter((document) => {
                if (applicant.amlCheck && document.documentType === 'compliance_document') {
                  return;
                }
                return document;
              })
              .filter((ele) => ele)
              .filter((document) => {
                if (
                  isAccreditedDocumentVisible(
                    document.documentType,
                    applicant.isAccreditedInvestor || ''
                  )
                ) {
                  return;
                }
                return document;
              })
              .filter((ele) => ele)
              .map((_doc) => {
                if (['accreditedInvestorCertificate'].includes(_doc.documentType)) {
                  return { ..._doc, required: 'true' };
                }
                return _doc;
              })
              .filter((_doc) => {
                if (!hasPOA && doNotWishToFillRiskProfile && _doc.documentType === 'riskDisclosure')
                  return;
                return _doc;
              })
              .filter((ele) => ele)
              .map((doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
                const { documentType, documentName, multipleFiles, required, options } = doc;
                const { documents: existingDocuments = [] } = applicant || {};
                const docsOfCurrentDocType = existingDocuments
                  .filter((doc) => doc.documentType === documentType)
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
                return {
                  documentType: documentType,
                  documentName: documentName,
                  documentsList: docsOfCurrentDocType.length
                    ? docsOfCurrentDocType.map((doc, ind) => {
                        const {
                          id,
                          documentName = '',
                          documentType = '',
                          documentId = '',
                          isActive = true,
                          file = undefined,
                        } = doc;
                        return {
                          id,
                          applicantId: applicant?.id,
                          documentType,
                          documentName,
                          documentId,
                          isActive,
                          file,
                          options,
                          required,
                          isVisible: true,
                          uniqueKey: (applicant_index.toString() +
                            '-' +
                            index.toString() +
                            '-' +
                            ind.toString()) as string,
                        };
                      })
                    : [
                        {
                          documentType,
                          documentName,
                          required,
                          options,
                          isVisible: true,
                          uniqueKey: (applicant_index.toString() +
                            '-' +
                            index.toString() +
                            '-' +
                            '0') as string,
                          ...document_object,
                        },
                      ],
                  required,
                  multipleFiles,
                  options,
                };
              })
          : [],
      kraDocument: applicant.kraDocument,
    };
  });
};

export const nomineesDocuments = (
  application: ApplicationProps | null,
  nomineeValidationDate: string,
  documentsData?: Document | string
): nomineeDocumentProps[] => {
  const { nominees = [], hasPOA = false, createdAt = '' } = application || {};
  return nominees.map((nominee, nominee_index) => {
    return {
      nomineedocuments: !checkValidationBasedOnDate(createdAt, nomineeValidationDate)
        ? typeof documentsData !== 'undefined'
          ? (isMinor(nominee.dateOfBirth || '')
              ? [
                  ...((documentsData as Document)[APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_DOC] ||
                    []),
                  ...((documentsData as Document)[
                    APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_GUARDIAN
                  ] || []),
                ].map((doc) => {
                  if (doc.documentType === 'nomineeIdProof') {
                    return { ...doc, required: 'false' };
                  }
                  return doc;
                })
              : (documentsData as Document)[APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_DOC] || []
            )
              .filter((document) => {
                if (!hasPOA && document.documentType === 'nomination_form') {
                  return;
                }
                return document;
              })
              .filter((ele) => ele)
              .map((document) => {
                if (
                  nominee.nominationDocumentCheck &&
                  document.documentType === 'nomination_form'
                ) {
                  return { ...document, required: 'true' };
                }
                return document;
              })
              .map((doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
                const { documentType, documentName, multipleFiles, required, options } = doc;
                const { nomineedocuments: existingDocuments = [] } = nominee || {};
                const docsOfCurrentDocType = existingDocuments
                  .filter((doc) => doc.documentType === documentType)
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
                return {
                  documentType: documentType,
                  documentName: documentName,
                  documentsList: docsOfCurrentDocType.length
                    ? docsOfCurrentDocType.map((doc, ind) => {
                        const {
                          documentName = '',
                          documentType = '',
                          documentId = '',
                          isActive = true,
                          file = undefined,
                        } = doc;
                        return {
                          documentType,
                          documentName,
                          documentId,
                          isActive,
                          file,
                          options,
                          required,
                          isVisible: true,
                          uniqueKey: (nominee_index.toString() +
                            '-' +
                            index.toString() +
                            '-' +
                            ind.toString()) as string,
                        };
                      })
                    : [
                        {
                          documentType,
                          documentName,
                          required,
                          options,
                          isVisible: true,
                          uniqueKey: (nominee_index.toString() +
                            '-' +
                            index.toString() +
                            '-' +
                            '0') as string,
                          ...document_object,
                        },
                      ],
                  required,
                  multipleFiles,
                  options,
                };
              })
          : []
        : [],
    };
  });
};

export const nonIndividualDocuments = (
  application: ApplicationProps | null,
  role: USER_ROLES,
  documentsData: individuals_Poa_nonIndividuals_Documents[],
  dematDocumentValidation: string,
  poaDocValidation: string
): nonIndDocumentProps[] => {
  const {
    applicants = [],
    hasPOA = false,
    createdAt = '',
    doNotWishToFillRiskProfile = false,
    scheme,
  } = application || {};
  return applicants.map((applicant, applicant_index) => {
    const tempDocData = documentsData;
    // Find and remove the FATCA document
    const fatcaDocumentIndex = tempDocData.findIndex((doc) => doc?.documentType === 'fatca');
    const fatcaDocument = fatcaDocumentIndex && documentsData.splice(fatcaDocumentIndex, 1)[0];
    // Append the FATCA document to the end of the array
    fatcaDocument && tempDocData.push(fatcaDocument);
    return {
      documents: tempDocData
        .filter((doc) => {
          if (
            checkIfApplicationIsNonIndividualPOA(application as ApplicationProps) &&
            poaFirstApplicantDocumentVisible(doc?.documentType, applicant?.applicant_type)
          ) {
            return;
          }
          return doc;
        })
        .filter((ele) => ele)
        .filter((doc) => {
          if (
            ['authorized_signatories_1', 'authorized_signatories_2'].includes(doc?.documentType) &&
            checkValidationBasedOnDate(createdAt, poaDocValidation)
          ) {
            return;
          }
          return doc;
        })
        .filter((doc) => doc)
        .map((_doc) => {
          if (
            [USER_ROLES.POAAPPROVER, USER_ROLES.AMCAPPROVER].includes(role) &&
            poaApproverMandetryDocs(_doc?.documentType)
          ) {
            return {
              ..._doc,
              required:
                ['authorized_signatories_1', 'authorized_signatories_2'].includes(
                  _doc.documentType
                ) && checkValidationBasedOnDate(createdAt, poaDocValidation)
                  ? 'false'
                  : 'true',
            };
          }
          return _doc;
        })
        .filter((document) => {
          if (applicant.amlCheck && document?.documentType === 'compliance_document') {
            return;
          }
          if (
            fatcaDocumentCheck(
              hasPOA as boolean,
              !applicant.fatcaCrsDocumentCheck as boolean,
              document?.documentType
            )
          ) {
            return;
          }
          return document;
        })
        .filter((ele) => ele)
        .filter((doc) => {
          if (
            scheme?.schemeCode.toUpperCase() !== 'LS' &&
            doc?.documentType === 'annual_declaration_consent'
          ) {
            return;
          }
          return doc;
        })
        .filter((ele) => ele)
        .map((_doc) => {
          if (
            fatcaDocumentCheck(
              hasPOA as boolean,
              applicant.fatcaCrsDocumentCheck as boolean,
              _doc?.documentType
            )
          ) {
            return { ..._doc, required: 'true' };
          }
          return _doc;
        })
        .map((_doc) => {
          if (
            _doc.documentType === 'cml' &&
            checkValidationBasedOnDate(createdAt, dematDocumentValidation)
          ) {
            return { ..._doc, required: 'true' };
          }
          if (
            scheme?.schemeCode.toUpperCase() === 'LS' &&
            _doc.documentType === 'annual_declaration_consent'
          ) {
            return { ..._doc, required: 'true' };
          }
          return _doc;
        })
        .filter((_doc) => {
          if (!hasPOA && doNotWishToFillRiskProfile && _doc.documentType === 'riskDisclosure')
            return;
          return _doc;
        })
        .filter((ele) => ele)
        .map((doc, index) => {
          const { documentType, documentName, multipleFiles, required, options } = doc;
          const { documents: existingDocuments = [] } = applicant || {};
          const docsOfCurrentDocType = existingDocuments
            .filter((doc) => doc.documentType === documentType)
            .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
          return {
            documentType: documentType,
            documentName: documentName,
            documentsList: docsOfCurrentDocType.length
              ? docsOfCurrentDocType.map((doc, ind) => {
                  const {
                    id,
                    documentName = '',
                    documentType = '',
                    documentId = '',
                    isActive = true,
                    file = undefined,
                  } = doc;
                  return {
                    id,
                    applicantId: applicant?.id,
                    documentType,
                    documentName,
                    documentId,
                    isActive,
                    file,
                    options,
                    required,
                    isVisible: true,
                    uniqueKey: (applicant_index.toString() +
                      '-' +
                      index.toString() +
                      '-' +
                      ind.toString()) as string,
                  };
                })
              : [
                  {
                    documentType,
                    documentName,
                    required,
                    options,
                    isVisible: true,
                    uniqueKey: (applicant_index.toString() +
                      '-' +
                      index.toString() +
                      '-' +
                      '0') as string,
                    ...document_object,
                  },
                ],
            required,
            multipleFiles,
            options,
          };
        }),
      kraDocument: applicant.kraDocument,
      fatcaCrsDocumentCheck: applicant.fatcaCrsDocumentCheck,
    };
  });
};

export function getDocNumberForMultipleDocs(docArraylength: number): string | null {
  switch (docArraylength.toString()) {
    case '1':
      return 'first ';
    case '2':
      return 'second ';
    case '3':
      return 'third ';
    case '4':
      return 'forth';
    default:
      return '';
  }
}

export const getDownloadUrl = (value: string | number | null | undefined | FileType): string => {
  return typeof value === 'object' && value !== null && 'url' in value ? value.url : '';
};

export const getUpdatedDocuments = (asDoc: DocumentType[]): DocumentType[] => {
  return asDoc?.reduce((prev: DocumentType[], curr) => {
    const { documentType } = curr;
    const modifiedDocTypes = prev.map((doc) => doc.documentType);
    const docsOfCurrentDocType = asDoc?.filter(
      (doc) => doc.documentType === documentType && !modifiedDocTypes.includes(documentType)
    );
    const updatedDocs =
      (docsOfCurrentDocType || [])?.length > 1
        ? docsOfCurrentDocType?.map((doc, index) => ({
            ...doc,
            documentName: `${
              doc.documentName?.toLowerCase() === getFieldTitle[doc.documentType]?.toLowerCase()
                ? doc.file?.name
                : doc.documentName
            } (${index + 1})`,
          }))
        : docsOfCurrentDocType;
    return [...prev, ...(updatedDocs || [])];
  }, []) as DocumentType[];
};

export const decodeState = (stateValue = ''): null | AuthTokenType => {
  if (!stateValue) return null;
  return JSON.parse(atob(stateValue));
};

/**
 * Replace with your file URL or API endpoint
 * @param fileUrl
 *
 * Desired file name
 * @param fileName
 */
export const handleDownload = async (fileUrl: string, fileName: string): Promise<void> => {
  // Fetch the file data

  const response = await fetch(fileUrl);
  if (!response.ok) {
    throw new Error(en.networkText.unableToProcess);
  }
  const blob = (await response.blob()) as Blob;

  // Create a URL for the blob
  const blobUrl = URL.createObjectURL(blob);

  // Create an anchor element and trigger the download
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  // Clean up
  link.remove();
  URL.revokeObjectURL(blobUrl);
};
