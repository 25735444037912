import { AmlDetails, CommonLayout, CommonLayoutForPOA, Footer } from '../commonComponents';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {
  Box,
  Button,
  CardMedia,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  Dialog,
  Tooltip,
} from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  getApplicationDetails,
  getDocuments,
  getInvestorProfileData,
  getUboTypes,
  isFormValidForSubmission,
  nonIndividualFormValidForSubmission,
  updateApplication,
} from '../../redux-store/actions/application';
import ContactDetails from './contactDetails';
import KycDetails from './kycDetails';
import FatcaDetails from './FatcaDetails';
import NomineeDetails from './nomineeDetails';
import InvestAndPaymentDetails from './InvestmentAndPayment';
import BankDetails from './BankDetails';
import DocumentDetails from './DocumentDetails';
import {
  ApplicationProps,
  individuals_Poa_nonIndividuals_Documents,
  mdmsInvestorProfileQuestion,
  nationaliyType,
  RiskProfileMaster,
  uboTypes,
} from '../../redux-store/types/api-types';
import ContributorDetails from './contributor';
import {
  REJECTED_ROLES,
  USER_ROLES,
  APPLICATION_STATUS,
  ApplicationStatusBasedOnRole,
  APPLICATION_TYPE,
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  IndividualApplicationStatusBasedOnRole,
  APPLICATION_STATUS_AMCAPPROVER_individual,
  APPLICATION_LISTING_STATUS,
  InvalidCommentsMessage,
} from '../../utils/constant';
import { RootStateType } from '../../redux-store/reducers';
import { useHistory, useRouteMatch } from 'react-router';
import * as yup from 'yup';
import { Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { MFTextField } from '../../lib/formik';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LoadingButton from '@mui/lab/LoadingButton';
import { formatShortDateAndTime } from '../../utils/date';
import {
  applyRoleBasedStatus,
  checkIfApplicationIsNonIndividual,
  checkInvestorProfilebasedOnScheme,
  checkIfApplicationIsNonIndividualPOA,
  sendApplication_Nri,
  checkValidationBasedOnDate,
  checkIfApplicationIsIndividualPOA,
  handleDownload,
} from '../../utils/utilityFunctions';
import { NonIndividualApplicationLayout } from '../NonIndividualApplicationDetails';
import { showError } from '../../redux-store/actions/auth';
import { ConfirmationDialog } from '../commonComponents';
import DistributorDetails from './rmDistributor';
import { getNationalityList, riskProfileMasterData } from '../../redux-store/actions';
import RiskProfiles from './riskProfileDetails';
import InvestorProfilesQuestion from './investorQuestionnaire';
import Comments from './Comments';
import Declarations from '../Investments/Declarations';
import { validCommentRegex } from '../../utils/regex';
import { useFeatureToggles } from '../../utils/toggler';
import AuthorizedSignatories from '../NonIndividualApplicationDetails/AuthorisedSignatories';

export const Item = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: '10px',
  marginLeft: '2px',
  color: theme.palette.text.secondary,
  fontSize: '15px',
  fontWeight: 500,
  letterSpacing: '0.5px',
}));
export const ItemDetails = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  fontSize: '16px',
  marginLeft: '2px',
  fontWeight: 700,
  letterSpacing: '0.5px',
  maxWidth: '100%',
  overflowWrap: 'anywhere',
}));
export const Gridstyles = styled('div')(({ theme }) => ({
  paddingLeft: '10%',
  [theme.breakpoints.only('xs')]: {
    paddingLeft: 0,
  },
}));

export function RejectedDetails({
  title,
  description,
}: {
  title: string | null;
  description: string | null;
}): JSX.Element {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography sx={{ fontSize: 17, fontWeight: 500, color: 'text.secondary' }}>
          {title}:{' '}
          <Typography
            component="span"
            sx={{
              color: 'text.secondary',
              fontSize: '16px',
              letterSpacing: '0.4px',
              fontWeight: 700,
              ml: { sm: 0.5 },
            }}>
            {description}
          </Typography>
        </Typography>
      </Grid>
    </>
  );
}

export function ApplicationDetailsCommonLayout({
  loading,
  application,
}: {
  loading: boolean;
  application: ApplicationProps;
}): JSX.Element {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dateFeatureToggles = useFeatureToggles();

  const { nomineeValidationDate } = dateFeatureToggles;
  return (
    <>
      {loading && <LinearProgress />}
      {application && (
        <>
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
            }}
            mb={3}>
            <Box sx={{ p: 2 }}>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  mb={2}>
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: 'text.secondary',
                    }}>
                    Application ID: {application.applicationNumber}
                  </Typography>
                  {application.folio_no && (
                    <Typography
                      sx={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: 'text.secondary',
                      }}>
                      Folio Number: {application.folio_no}
                    </Typography>
                  )}
                  <Typography
                    className={
                      application.hasPOA
                        ? role === USER_ROLES.POAAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : role === USER_ROLES.AMCAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : APPLICATION_LISTING_STATUS[application.status]
                        : checkIfApplicationIsNonIndividual(application)
                        ? role === USER_ROLES.AMCAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : APPLICATION_LISTING_STATUS[application.status]
                        : !checkIfApplicationIsNonIndividual(application) &&
                          role === USER_ROLES.AMCAPPROVER
                        ? IndividualApplicationStatusBasedOnRole[role][application.status]
                        : APPLICATION_LISTING_STATUS[application.status]
                    }
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: 'text.secondary',
                      '&.Draft .MuiTypography-root': {
                        color: 'rgba(51, 127, 201, 0.7)',
                      },
                      '&.Completed .MuiTypography-root': {
                        fontWeight: 600,
                        color: 'rgba(35, 133, 63, 0.7)',
                      },
                      '&.Rejected .MuiTypography-root': {
                        color: '#BD2932',
                        fontWeight: 500,
                      },
                    }}>
                    Status:{' '}
                    <Typography
                      component="span"
                      sx={{
                        color: '#B78813',
                        fontSize: '16px',
                        letterSpacing: '0.4px',
                        ml: 0.5,
                      }}>
                      {application.hasPOA
                        ? role === USER_ROLES.POAAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : role === USER_ROLES.AMCAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : APPLICATION_LISTING_STATUS[application.status]
                        : checkIfApplicationIsNonIndividual(application)
                        ? role === USER_ROLES.AMCAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : APPLICATION_LISTING_STATUS[application.status]
                        : !checkIfApplicationIsNonIndividual(application) &&
                          role === USER_ROLES.AMCAPPROVER
                        ? IndividualApplicationStatusBasedOnRole[role][application.status]
                        : APPLICATION_LISTING_STATUS[application.status]}
                    </Typography>
                  </Typography>
                  {[
                    USER_ROLES.RM,
                    USER_ROLES.DISTRIBUTOR,
                    USER_ROLES.SUBDISTRIBUTOR,
                    USER_ROLES.AMCAPPROVER,
                    USER_ROLES.AMC_ADMIN,
                  ].includes(role) &&
                    application.signedLeegalityFile?.url && (
                      <Tooltip title={'Download'}>
                        <IconButton
                          sx={{
                            p: 0,
                            pr: 0.5,
                            ':hover': {
                              bgcolor: '#F4FCFC',
                            },
                            cursor: 'pointer',
                            pointerEvents: 'all',
                          }}
                          onClick={async () => {
                            try {
                              application?.signedLeegalityFile?.url &&
                                (await handleDownload(
                                  application?.signedLeegalityFile?.url as string,
                                  `${application.applicationNumber}.pdf`
                                ));
                            } catch (e) {
                              console.error((e as Error).message);
                            }
                          }}>
                          <CardMedia
                            component="img"
                            src={'/images/download-icon.png'}
                            alt="Download"
                            sx={{ width: '25px' }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                </Grid>
                {application.status === 'rejected' && (
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={1}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <RejectedDetails
                      title={'Rejected by'}
                      description={REJECTED_ROLES[application.rejectedByRole]}
                    />
                    <RejectedDetails
                      title={'Rejected on'}
                      description={formatShortDateAndTime(application.rejectedAt)}
                    />
                    <RejectedDetails
                      title={'Reason for rejection'}
                      description={application.rejectedReason}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>

          {![USER_ROLES.INVESTOR, USER_ROLES.AUTHORISEDSIGNATORY].includes(role) && (
            <>
              <AmlDetails
                applicants={application.applicants}
                applicationType={application.applicationType}
              />
              {application.comments?.length > 0 && <Comments application={application} />}
              <DistributorDetails application={application} />
            </>
          )}
          <ContributorDetails
            applicants={application.applicants}
            modeOfHolding={application.modeOfHolding}
            modeOfOperation={application.modeOfOperation}
          />
          <ContactDetails applicants={application.applicants} />
          <KycDetails application={application} />
          <FatcaDetails applicants={application.applicants} />
          {!checkValidationBasedOnDate(application?.createdAt || '', nomineeValidationDate) && (
            <NomineeDetails nominees={application.nominees} />
          )}
          <BankDetails
            banks={application.banks}
            createdAt={application?.createdAt || ''}
            isSameBank={application?.isSameBank}
          />
          <RiskProfiles application={application} />
          <InvestAndPaymentDetails application={application} />
          {/* {checkInvestorProfilebasedOnScheme(application?.scheme?.schemeCode || '') && (
            <InvestorProfilesQuestion application={application} />
          )} */}
          <DocumentDetails application={application} />

          {checkIfApplicationIsIndividualPOA(application) && (
            <AuthorizedSignatories application={application} />
          )}
          {![USER_ROLES.INVESTOR, USER_ROLES.AUTHORISEDSIGNATORY].includes(role) && (
            <Declarations application={application} />
          )}
        </>
      )}
    </>
  );
}

export const RejectApproveButtons = ({
  handleOpen,
  handleDialogOpen,
  approve,
  reject,
  handleSendBack,
  sendBack,
  displaySendBack = true,
}: {
  handleOpen: () => void;
  handleDialogOpen: () => void;
  approve: boolean;
  reject: boolean;
  handleSendBack?: () => void;
  sendBack?: boolean;
  displaySendBack?: boolean;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <Grid display="flex" justifyContent="center" alignItems="center">
      <LoadingButton
        loadingPosition="start"
        type="submit"
        loading={reject}
        variant="outlined"
        // sx={{
        //   fontSize: 15,
        //   minWidth: 180,
        //   letterSpacing: 2,
        //   fontWeight: 400,
        // }}
        sx={{
          color: 'primary.main',
          minWidth: { xs: '100px', sm: '150px', md: '200px' },
          mt: 4,
          fontWeight: 600,
          lineHeight: 1.1,
          mr: 3,
        }}
        onClick={handleOpen}
        disabled={approve || sendBack}>
        Reject
      </LoadingButton>
      <LoadingButton
        loadingPosition="start"
        type="submit"
        loading={approve}
        variant="contained"
        // sx={{
        //   fontSize: 15,
        //   minWidth: 180,
        //   letterSpacing: 2,
        //   fontWeight: 400,
        // }}
        sx={{
          color: 'common.white',
          minWidth: { xs: '100px', sm: '150px', md: '200px' },
          mt: 4,
          fontWeight: 600,
          lineHeight: 1.1,
          ml: 3,
        }}
        onClick={handleDialogOpen}
        disabled={reject || sendBack}>
        Approve
      </LoadingButton>
      {displaySendBack && (
        <LoadingButton
          loadingPosition="start"
          type="submit"
          loading={sendBack}
          variant="contained"
          sx={{
            color: 'common.white',
            minWidth: { xs: '100px', sm: '150px', md: '200px' },
            mt: 4,
            fontWeight: 600,
            lineHeight: 1.1,
            ml: 3,
          }}
          onClick={handleSendBack}
          disabled={reject || approve}>
          Send Back
        </LoadingButton>
      )}
      {/* <Button
        variant="contained"
        sx={{
          color: 'common.white',
          minWidth: { xs: '100px', sm: '150px', md: '200px' },
          mt: 4,
          fontWeight: 600,
          lineHeight: 1.1,
          ml: 3,
        }}
        onClick={handleDialogOpen}>
        Approve
      </Button> */}
    </Grid>
  );
};

//Read only view for POA and Non Individual Application for AMC Approver,Individual Application for AMC Approver and POA Approver
const PoaOrNIApplicationDetails = ({
  loading,
  application,
}: {
  loading: boolean;
  application: ApplicationProps;
}): JSX.Element => {
  const { status, editable, applicationType } = application || {};
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const applicationDetailRef = useRef<HTMLDivElement>();
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const dateFeatureToggles = useFeatureToggles();

  const validateForm = async () => {
    const nationalitiesMdmsMasters = (await dispatch(
      getNationalityList()
    )) as unknown as nationaliyType;
    const { applicants = [] } = application || {};
    const getInvestorType = applicants[0]?.investorType;
    const documentsResponse = (await dispatch(getDocuments())) as unknown as any;
    const risk = (await dispatch(riskProfileMasterData())) as unknown as RiskProfileMaster;
    const questionsInvestor = (await dispatch(
      getInvestorProfileData()
    )) as unknown as mdmsInvestorProfileQuestion;
    if (applicationType === APPLICATION_TYPE.INDIVIDUAL) {
      await isFormValidForSubmission(
        application as ApplicationProps,
        risk,
        questionsInvestor.investor_question_individual,
        dateFeatureToggles,
        true,
        true,
        nationalitiesMdmsMasters,
        documentsResponse,
        role
      );
    }
    if (applicationType === APPLICATION_TYPE.NON_INDIVIDUAL && getInvestorType) {
      const uboTypesMdms = (await dispatch(getUboTypes())) as unknown as uboTypes;
      await nonIndividualFormValidForSubmission(
        application,
        documentsResponse[
          `${
            checkIfApplicationIsNonIndividualPOA(application)
              ? `${getInvestorType}_poa`
              : getInvestorType
          }`
        ] as individuals_Poa_nonIndividuals_Documents[],
        questionsInvestor.investor_question_non_individual,
        nationalitiesMdmsMasters.countries,
        uboTypesMdms,
        risk,
        dateFeatureToggles,
        true,
        role
      );
    }
  };

  const handleOpen = () => setOpen(true);
  const handleDialogOpen = async () => {
    try {
      setApproveLoading(true);
      await validateForm();
      setApproveLoading(false);
      setOpenDialog(true);
    } catch (e) {
      setApproveLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };
  const initialCommentValues: Partial<ApplicationProps> = { comment: '' };
  const Commentvalidation = yup.object().shape({
    comment: yup
      .string()
      .matches(validCommentRegex, InvalidCommentsMessage)
      .required('Please provide Comment'),
  });

  const [openSendBackDialog, setOpenSendBackDialog] = useState(false);
  const [sendBackLoading, setSendBackLoading] = useState(false);

  const handleSendBack = () => setOpenSendBackDialog(true);
  const handleSendBackClose = () => {
    setOpenSendBackDialog(false), setSendBackLoading(false);
  };

  const handleClose = () => {
    setOpen(false), setApproveLoading(false);
  };
  const handleDialogClose = () => {
    setOpenDialog(false), setRejectLoading(false);
  };

  const initialValues: Partial<ApplicationProps> = { rejectedReason: '' };
  const resonValidation = yup.object().shape({
    rejectedReason: yup
      .string()
      .matches(validCommentRegex, InvalidCommentsMessage)
      .required('Please enter the reason for rejection'),
  });
  const onSubmit = async (values: Partial<ApplicationProps>) => {
    try {
      handleClose();
      setRejectLoading(true);
      if (application) {
        await dispatch(
          updateApplication({
            body: {
              ...application,
              rejectedReason: values.rejectedReason,
              status: 'rejected',
            },
            applicationId: application.id,
          })
        );
        history.push('/applications-list');
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setRejectLoading(false);
    }
  };
  const onSendBackSubmit = async (values: Partial<ApplicationProps>) => {
    try {
      handleSendBackClose();
      setSendBackLoading(true);
      if (application) {
        await dispatch(
          updateApplication({
            body: {
              ...application,
              comment: values.comment,
              status: 'draft',
              applicationSentBack: true,
            },
            applicationId: application.id,
          })
        );
        history.push('/applications-list');
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setSendBackLoading(false);
    }
  };
  return (
    <Box
      sx={{
        bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
      }}>
      <CommonLayoutForPOA />
      <Box sx={{ py: 3, px: { xs: 2, sm: 10 }, mt: { xs: 7, sm: 10 } }} ref={applicationDetailRef}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            justifyContent: 'space-between',
            // mt: 5,
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              component={RouterLink}
              to="/applications-list"
              disabled={approveLoading || rejectLoading}>
              <NavigateBeforeIcon fontSize="large" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main' }}>
              Application Details
            </Typography>
          </Box>
          {editable && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/edit-icon-outlined.svg"
                alt="Edit Icon"
                sx={{ width: 'unset' }}
                onClick={() =>
                  history.push(
                    [USER_ROLES.POAAPPROVER, USER_ROLES.AMCAPPROVER].includes(role) &&
                      !checkIfApplicationIsNonIndividual(application)
                      ? `/investment-details/edit-application`
                      : `/non-individual-application-edit`,
                    {
                      id: application?.id,
                    }
                  )
                }
              />
            </IconButton>
          )}
        </Box>
        <IconButton
          sx={{
            position: 'fixed',
            right: 0,
            bottom: 75,
            borderRadius: '5px  0 0 5px',
            '&,:hover': {
              bgcolor: 'primary.main',
            },
          }}
          onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
          <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
        </IconButton>
        {applicationType === APPLICATION_TYPE.NON_INDIVIDUAL ? (
          <NonIndividualApplicationLayout loading={loading} application={application} />
        ) : (
          <ApplicationDetailsCommonLayout loading={loading} application={application} />
        )}
        {application &&
          ((USER_ROLES.AMCAPPROVER === role && status === 'sent_to_amc_approver') ||
          (USER_ROLES.POAAPPROVER === role && status === 'sent_to_poa_approver') ? (
            <RejectApproveButtons
              handleOpen={handleOpen}
              handleDialogOpen={handleDialogOpen}
              approve={approveLoading}
              reject={rejectLoading}
              sendBack={sendBackLoading}
              handleSendBack={handleSendBack}
            />
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                sx={{
                  color: 'common.white',
                  minWidth: '200px',
                  mt: 4,
                  fontWeight: 600,
                  lineHeight: 1.5,
                }}
                onClick={() => {
                  history.push('/applications-list');
                }}>
                Done
              </Button>
            </Box>
          ))}
        <Dialog
          onClose={handleClose}
          open={open}
          sx={{
            '.MuiPaper-root ': {
              p: 4,
              width: { xs: '70%', sm: '70%' },
            },
          }}>
          <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box sx={{ mb: 2 }}>
            <CardMedia
              component="img"
              src="/images/warning-icon-red.svg"
              sx={{ width: 'unset', mx: 'auto' }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: '100%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontSize: 18,
                fontWeight: 500,
                textAlign: 'center',
              },
            }}>
            <Typography sx={{ color: 'primary.main' }}>
              Are you sure you want to{' '}
              <Typography component="span" sx={{ color: '#BD2932' }}>
                Reject
              </Typography>{' '}
              the application
            </Typography>
            <Typography sx={{ color: '#BD2932', mt: 5 }}>
              {' '}
              Give reason for application rejection *
            </Typography>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={resonValidation}>
              {({ handleSubmit }) => (
                <Box component="form" noValidate onSubmit={handleSubmit}>
                  <MFTextField
                    name="rejectedReason"
                    multiline
                    rows={4}
                    placeholder="Type your answer here..."
                  />

                  <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
                    <Button
                      onClick={() => {
                        handleClose();
                      }}
                      variant="outlined"
                      sx={{
                        fontSize: 15,
                        color: 'primary.main',
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 500,
                      }}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: 15,
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 400,
                      }}
                      type="submit">
                      Continue
                    </Button>
                  </Grid>
                </Box>
              )}
            </Formik>
          </Box>
        </Dialog>
        <Dialog
          onClose={handleDialogClose}
          open={openDialog}
          sx={{
            '.MuiPaper-root ': {
              p: 4,
              width: { xs: '70%', sm: '70%' },
            },
          }}>
          <IconButton onClick={handleDialogClose} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box
            sx={{
              width: { xs: '100%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontSize: 20,
                fontWeight: 500,
                textAlign: 'center',
              },
            }}>
            <Typography sx={{ color: 'primary.main' }}>
              Are you sure you want to{' '}
              <Typography component="span" sx={{ color: 'green' }}>
                Approve
              </Typography>{' '}
              the application
            </Typography>
          </Box>
          <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
            <Button
              onClick={() => {
                handleDialogClose();
              }}
              variant="outlined"
              sx={{
                fontSize: 15,
                color: 'primary.main',
                minWidth: 180,
                letterSpacing: 2,
                fontWeight: 500,
              }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                fontSize: 15,
                minWidth: 180,
                letterSpacing: 2,
                fontWeight: 400,
              }}
              onClick={async () => {
                try {
                  handleDialogClose();
                  setApproveLoading(true);
                  await dispatch(
                    updateApplication({
                      body: {
                        ...application,
                        status:
                          USER_ROLES.POAAPPROVER === role &&
                          application?.status === 'sent_to_poa_approver'
                            ? 'sent_to_amc_approver'
                            : USER_ROLES.AMCAPPROVER === role &&
                              application?.status === 'sent_to_amc_approver'
                            ? application?.hasPOA
                              ? 'sent_to_authorised_signatories'
                              : sendApplication_Nri(application) ||
                                (AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                                  !checkIfApplicationIsNonIndividual(application))
                              ? 'sent_to_applicant1'
                              : 'sent_to_authorised_signatories'
                            : application?.status,
                      },
                      applicationId: application.id,
                    })
                  );
                  history.push('/applications-list');
                } catch (e) {
                  console.error((e as Error).message);
                } finally {
                  setApproveLoading(false);
                }
              }}>
              Continue
            </Button>
          </Grid>
        </Dialog>
        <Dialog
          onClose={handleSendBackClose}
          open={openSendBackDialog}
          sx={{
            '.MuiPaper-root ': {
              p: 4,
              width: { xs: '70%', sm: '70%' },
            },
          }}>
          <IconButton onClick={handleSendBackClose} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box
            sx={{
              width: { xs: '100%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontSize: 18,
                fontWeight: 500,
                textAlign: 'center',
              },
            }}>
            <Typography sx={{ color: 'primary.main' }}>
              Are you sure you want to{' '}
              <Typography component="span" sx={{ color: '#BD2932' }}>
                Send Back
              </Typography>{' '}
              the application
            </Typography>
            <Typography sx={{ color: '#BD2932', mt: 5 }}>
              {' '}
              Give Comments for application Send Back *
            </Typography>
            <Formik
              initialValues={initialCommentValues}
              onSubmit={onSendBackSubmit}
              validationSchema={Commentvalidation}>
              {({ handleSubmit }) => (
                <Box component="form" noValidate onSubmit={handleSubmit}>
                  <MFTextField
                    name="comment"
                    multiline
                    rows={4}
                    placeholder="Type your answer here..."
                  />

                  <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
                    <Button
                      onClick={handleSendBackClose}
                      variant="outlined"
                      sx={{
                        fontSize: 15,
                        color: 'primary.main',
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 500,
                      }}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: 15,
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 400,
                      }}
                      type="submit">
                      Continue
                    </Button>
                  </Grid>
                </Box>
              )}
            </Formik>
          </Box>
        </Dialog>
      </Box>
      <Footer />
    </Box>
  );
};

export default function ApplicationDetails(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState<ApplicationProps>();
  const applicationDetailRef = useRef<HTMLDivElement>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDialog, setDialog] = useState(false);
  const { path } = useRouteMatch();

  useEffect(() => {
    (async function () {
      try {
        const _application = (await dispatch(
          getApplicationDetails(id)
        )) as unknown as ApplicationProps;
        setApplication(_application);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        setLoading(false);
      }
    })();
    setLoading(true);
  }, []);

  const { editable, hasPOA, applicationSentBack = false } = application || {};
  const initialCommentValues: Partial<ApplicationProps> = { comment: '' };
  const Commentvalidation = yup.object().shape({
    comment: yup
      .string()
      .matches(validCommentRegex, InvalidCommentsMessage)
      .required('Please provide Comment'),
  });
  const handleSendBack = () => setOpenSendBackDialog(true);
  const [openSendBackDialog, setOpenSendBackDialog] = useState(false);
  const [sendBackLoading, setSendBackLoading] = useState(false);
  const handleSendBackClose = () => {
    setOpenSendBackDialog(false), setSendBackLoading(false);
  };
  const onSendBackSubmit = async (values: Partial<ApplicationProps>) => {
    try {
      handleSendBackClose();
      setSendBackLoading(true);
      const { id, status } = application || {};
      if (id) {
        await dispatch(
          updateApplication({
            body: {
              ...application,
              status: hasPOA
                ? 'sent_to_poa_approver'
                : sendApplication_Nri(application) ||
                  (AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role))
                ? 'sent_to_amc_approver'
                : !AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role)
                ? 'sent_to_applicant1'
                : status,
              comment: values.comment,
              applicationSentBack: false,
            },
            applicationId: id,
          })
        );
        history.push('/applications');
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setSendBackLoading(false);
    }
  };
  const handleSubmit = async () => {
    try {
      const { id, status } = application || {};
      setIsSubmitting(true);
      if (id) {
        await dispatch(
          updateApplication({
            body: {
              ...application,
              status: hasPOA
                ? 'sent_to_poa_approver'
                : // : (status === 'draft' || status === 'sent_to_amc_approver') &&
                sendApplication_Nri(application) ||
                  (AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role))
                ? 'sent_to_amc_approver'
                : !AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role)
                ? 'sent_to_applicant1'
                : status,
            },
            applicationId: id,
          })
        );
        history.push('/applications');
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return [
    USER_ROLES.AMC_ADMIN,
    USER_ROLES.RM,
    USER_ROLES.DISTRIBUTOR,
    USER_ROLES.SUBDISTRIBUTOR,
    USER_ROLES.AMC_ADMIN,
  ].includes(role) ? (
    <CommonLayout>
      <>
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
          ref={applicationDetailRef}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              component={RouterLink}
              to="/applications">
              <NavigateBeforeIcon fontSize="large" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main' }}>
              Application Details
            </Typography>
          </Box>
          {editable && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/edit-icon-outlined.svg"
                alt="Edit Icon"
                sx={{ width: 'unset' }}
                onClick={() => history.push(`/application`, { id })}
              />
            </IconButton>
          )}
        </Box>
        <IconButton
          sx={{
            position: 'fixed',
            right: 0,
            bottom: 75,
            borderRadius: '5px  0 0 5px',
            '&,:hover': {
              bgcolor: 'primary.main',
            },
          }}
          onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
          <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
        </IconButton>
        <ApplicationDetailsCommonLayout
          loading={loading}
          application={application as ApplicationProps}
        />
        {path === '/application-preview/:id' && editable && (
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              sx={{
                color: 'common.white',
                minWidth: '200px',
                mt: 4,
                fontWeight: 600,
                lineHeight: 1.5,
                fontSize: '16px',
              }}
              onClick={async () => {
                setDialog(true);
              }}
              disabled={isSubmitting || sendBackLoading}>
              {`${
                application?.hasPOA
                  ? 'Send to POA Approver'
                  : sendApplication_Nri(application) ||
                    (AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role))
                  ? 'Send to AIF Approver'
                  : 'Send to Investor'
              } ${isSubmitting || sendBackLoading ? '...' : ''}`}
            </Button>
          </Box>
        )}
        <Dialog
          onClose={handleSendBackClose}
          open={openSendBackDialog}
          sx={{
            '.MuiPaper-root ': {
              p: 4,
              width: { xs: '70%', sm: '70%' },
            },
          }}>
          <IconButton onClick={handleSendBackClose} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box
            sx={{
              width: { xs: '100%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontSize: 18,
                fontWeight: 500,
                textAlign: 'center',
              },
            }}>
            <Typography sx={{ color: 'primary.main', mt: 2 }}>
              Give Comments for application Send Back *
            </Typography>
            <Formik
              initialValues={initialCommentValues}
              onSubmit={onSendBackSubmit}
              validationSchema={Commentvalidation}>
              {({ handleSubmit }) => (
                <Box component="form" noValidate onSubmit={handleSubmit}>
                  <MFTextField
                    name="comment"
                    multiline
                    rows={4}
                    placeholder="Type your answer here..."
                  />

                  <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
                    <Button
                      onClick={() => {
                        handleSendBackClose();
                      }}
                      variant="outlined"
                      sx={{
                        fontSize: 15,
                        color: 'primary.main',
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 500,
                      }}
                      disabled={sendBackLoading}>
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={sendBackLoading}
                      variant="contained"
                      sx={{
                        fontSize: 15,
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 400,
                      }}
                      type="submit">
                      Continue
                    </LoadingButton>
                  </Grid>
                </Box>
              )}
            </Formik>
          </Box>
        </Dialog>
        <ConfirmationDialog
          message={`Are you sure you want to send it to ${
            application?.hasPOA
              ? 'POA Approver'
              : sendApplication_Nri(application) ||
                (AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role))
              ? 'AIF Approver'
              : 'Investor'
          }`}
          open={openDialog}
          setOpen={() => setDialog(false)}
          onSave={async () => {
            setDialog(false);
            (sendApplication_Nri(application) ||
              (AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role))) &&
            applicationSentBack
              ? handleSendBack()
              : handleSubmit();
          }}
          onCancel={() => setDialog(false)}
        />
      </>
    </CommonLayout>
  ) : (
    <PoaOrNIApplicationDetails loading={loading} application={application as ApplicationProps} />
  );
}
